.level {

  margin-bottom: 100px;

  &__item {
    display: flex!important;
    justify-content: flex-end;
  }

  &__text {
    width: 260px;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-right: 25px;

    ul {
      margin-top: 0;
    }

    li {
      border-bottom: 1px solid #FEB748;
      margin-top: 3px;
      padding-bottom: 3px;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      strong {
        color: $color-main;
      }
    }
  }

  &__image {
    width: 60%;
  }

  @include mq($until: lg) {
    &__text {
      width: 240px;
    }
  }

  @include mq($until: md) {
    &__image {
      width: 55%;
    }
  }

  @include mq($until: sm) {
    &__image {
      img {
        max-width: 290px;
      }
    }

    &__slider {
      padding: 0;
    }
  }

  @include mq($until: xs) {
    &__item {
      flex-direction: column;
    }

    &__text, &__image {
      width: 100%;
      margin-right: 0;
    }

    li, h4 {
      max-width: 300px;
      margin: 0 auto;
      width: 100%;
    }

    h4 {
      margin-bottom: 15px;
    }

    &__image {
      img {
        max-width: 290px;
        margin: 0 auto;
      }
    }
  }
}