.interior {
    position: relative;
    margin-top: 100px;
    margin-bottom: 400px;

    &__slider {
        svg {
            width: 100%;
            height: 40vw;
        }

        img {
            width: 100%;
            object-fit: cover;
            object-position: center 85%;
            height: 1380px;
        }
            
        .slider-arrow {
            color: black;
            background-color: white;

            &:hover {
                background-color: $color-main;
            }

            &_prev {
                bottom: 14%;
                left: calc(92% - 45px);

                i {
                    &::before {
                    background-image: url('../../images/arpb.svg');
                    }
                }
            }

            &_next {
                bottom: 15%;
                right: calc(6% - 45px); 

                i {
                    &::before {
                    background-image: url('../../images/arnb.svg');
                    }
                }
            }
        }
    }

    &__offer {
        display: flex;
        margin-top: 150px;
    }

    &__text {
        width: 55%;
    }

    &__buttons {
        width: 45%;
        padding-left: 10%;
        display: flex;
        flex-direction: column;
        align-self: center;

        a {
            display: block;
            margin: 10px auto;
            padding: 20px 50px;
            transition: all .3s;
            width: 430px;

            h3, span {
                color: white;
                margin: 0;
            }

            span {
                font-size: inherit;
                font-weight: 100;
            }

            &:hover {
                box-shadow: 0 0 10px rgba(65, 65, 65, 0.281);
                background-color: $color-main;
            }

            &:last-of-type {
                background-color: #767676;
                border-color: #767676;
            }

        }
    }

    @include mq($until: xxl) {
        margin-bottom: 200px;
    }

    @include mq($until: xl) {
        margin-bottom: 100px;
    }

    @include mq($until: lg) {
        &__slider {
    

            .slider-arrow {
    
                &_prev {
                    bottom: 13%;
                    left: calc(85% - 45px);
                }
    
                &_next {
                    bottom: 15%;
                    right: calc(10% - 45px); 
                }
            }
        }

        &__offer {
            margin-top: 100px;
            flex-direction: column;
        }

        &__text {
            width: 100%;
            margin-bottom: 30px;
        }
        
        &__buttons {
            padding: 0;
            width: auto;
        }
    }

    @include mq($until: md) {
        margin: 50px 0;

        &__slider {
            .slider-arrow {
    
                &_prev {
                    bottom: 13%;
                    left: calc(80% - 45px);
                }
    
                &_next {
                    bottom: 15%;
                    right: calc(13% - 45px); 
                }
            }
        }

        .slider-arrow {
            width: 35px;
            height: 35px;

            i {
                &::before {
                    width: 13px;
                }
            }
        }
    }

    @include mq($until: xs) {
        margin: 30px 0;

        &__slider {
            .slider-arrow {
    
                &_prev {
                    bottom: 7%;
                    left: calc(20% - 45px); 
                }
    
                &_next {
                    bottom: 15%;
                    right: calc(20% - 45px); 
                }
            }
        }

        &__offer {
            margin-top: 100px;
            flex-direction: column;
        }

        &__text {
            width: 100%;
            margin-bottom: 30px;
        }
        
        &__buttons {
            padding: 0;
            width: 100%;

            a {
                width: 100%;
                padding: 15px 40px;
            }

            h3 {
                font-size: rem(20px);
            }
        }
    }
}