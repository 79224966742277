.header {
  display: flex;
  justify-content: flex-start;
  position: relative;
  min-height: 170px;
  align-items: flex-end;
  padding-bottom: 40px;

  &__contact {
    position: absolute;
    right: -25px;
    top: 0;
    padding: 15px 70px 40px;
    background-image: url('./../../images/top-half.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

    span {
      color: inherit;
    }

    svg {
      width: 30px;
      fill: rgb(175, 175, 175);
      transition: all .3s;
      margin-left: 5px;

      &:hover {
        fill: gray;
      }
    }
  }
  
  &__tel {
    margin-right: 10px;
    font-weight: 600;

    svg {
      width: 40px;
    }
  }
  
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    margin-bottom: -15px;
  }

  &__nav {
    width: 100%;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: calc(100% - 30px);
    margin: 0;
    margin-left: 30px;
    &--fullscreen {
      flex-direction: column;
    }

    @include mq($until: md) {
      margin: 0 auto;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-dark;
    font-weight: 700;
    font-size: 1.1vw;
    font-family: $font-family-extra;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }

  @include mq($until: lg) {
    &__menuLink {
      font-size: 1.5vw;
    }
  }

  @include mq($until: md) {
    justify-content: space-between;
    min-height: 102px;

    &__menuLink {
      font-size: rem(25px);
      color: white;
    }

    &__logo {
      width: 150px;
    }

    &__contact {
      padding: 28px 105px 40px;
    }
  }

  @include mq($until: sm) {
    min-height: 90px;

    &__logo {
      width: 125px;
    }

    &__contact {
      padding: 17px 90px 40px;
    }
  }

  @include mq($until: xs) {
    min-height: 91px;

    &__logo {
      width: 100px;
      margin-bottom: -6px;
    }

    &__tel {
      margin-right: 0;
    }

    &__contact {
      right: -40px;

      span {
        display: none;
      }
    }

    .svg-icon {
      max-width: 27px;
    }
  }
}