.icons-set {
  display: grid;
  text-align: center;
  gap: 1vw;
  width: 70vw;
  margin: 150px auto;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    max-width: 200px;
    justify-self: center;

    img {
      width: 170px;
      border-radius: 15%;
      box-shadow: 0px 3px 6px #00000029;
    }

    &::before {
      content: none;
    }

    &.finance {
      flex-direction: row;
      max-width: unset;
      margin: 30px 0;
      width: 100%;

      img {
        width: 120px;
        margin-right: 20px;
      }

      @include mq($until: md) {
        align-items: start;
        margin: 20px 0;

        img {
          margin-right: 15px;
          width: 90px;
        }
      }

      @include mq($until: xs) {
        margin: 10px 0;

        h4 {
          margin-bottom: 5px;
        }

        img {
          width: 40px;
        }
      }
    }  
  }


  &__item-text {
      font-weight: 600;
      font-size: rem(16px);
      margin-top: 25px;
  }

  @include mq($until: md) {
    margin: 75px auto;
    &__item {
      img {
        width: 60px;
      }
    }
    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    margin: 50px auto;
    &__item-text {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    &__item {
      img {
        width: 80px;
      }
    }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include mq($until: sm) {
    &--4 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.d-grid {
  display: grid;
  column-gap: 40px;
}

