.decorator {
    background-color: $color-main-light;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    display: block;
}

.form1 {
    width: 100%;
    height: auto;
}