.subsite {

    background-image: url('../../images/top.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 30vh;

    .header {

        &__contact {
            background-image: url('./../../images/top-half-white.png');
        }

        .decorator {
            display: none;
        }
    }

    &__image {
        svg {
            width: 100%;
            height: 33vw;
            margin-bottom: 50px;
        }

        img {
            width: 100%;
            object-fit: cover;
            object-position: bottom;
            height: 636px;
        }
        position: relative;

        h1 {
            position: absolute;
            top: 35%;
            left: 10%;
            font-size: 5vw;
            color: white;
        }
    }

    p {
        img {
            border-radius: 25px;
        }
    }

    .map-container {
        margin-top: 0;
        margin-bottom: 50px;
        
        &> div {
        margin: 0;
        }
    }

    @include mq($until: lg) {
        .header {
            &__menuItem {
                margin-left: 1.6vw;
            }
        }
    }

    @include mq($until: md) {

        h1 {
            position: static;
            font-size: 5vw;
            color: #747474;
            margin-top: -30px;
            font-size: rem(30px);
        }

    }
}