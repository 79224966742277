.home {
    position: relative;
}

.top {
    position: relative;

    &__header {
        position: absolute;
        top: 50px;
        left: 70px;
        z-index: 2;
    }

    h1 {
        color: white;
        font-size: 2.7vw;
    }

    @include mq($until: xxl) {
        h1 {
            font-size: 2.5vw;
        }
    }

    @include mq($until: md) {

        &__header {
            top: 20px;
            left: 30px;
        }

        h1 {
            font-size: 4.5vw;
            text-shadow: 0 0 5px rgba(34, 34, 34, 0.8);
        }
    }

    @include mq($until: xs) {

        &__header {
            top: 10px;
            left: 20px;
        }

        h1 {
            font-size: 5.5vw;
        }
    }
}

.text-slider {
    display: flex;
    padding: 20px 120px;
    
    &__text {
        width: 50%;
        padding-right: 50px;
        display: flex;

        &> div {
            min-height: 200px;
            display: none;

            &.active {
                display: block;
            }

            @include mq($until: xxl) {
                min-height: 260px;   
            }
            @include mq($until: xl) {
                min-height: 300px;   
            }
            @include mq($until: lg) {
                min-height: 340px;   
            }
            @include mq($until: md) {
                min-height: 185px;   
            }
            @include mq($until: sm) {
                min-height: 195px;   
            }
            @include mq($until: xs) {
                min-height: 255px;   
            }
        }
    }

    &__icons {
        display: flex;
        width: 50%;
        justify-content: space-between;

    }

    .icon {
        
        svg {
            margin-top: -80px;
            width: 10vw;
            cursor: pointer;
            box-shadow: 0px -7px 26px #00000029;
            border-radius: 15%;

            .bg {
                fill: $color-main;
                transition: fill .3s;
            }


        }

        &.active, &:hover {
            .bg {
                fill: $color-extra;
            }
        }
    }

    @include mq($until: md) {

        flex-direction: column-reverse;

        padding: 20px 0;

        .icon svg {
            width: 13vw;
        }

        &__icons, &__text {
            width: 100%;
        }
        
        &__text {
            padding-right: 20px;
        }

        &__icons {
            justify-content: end;
            .icon svg {
                margin-left: 10px;
            }
        }
    }

    @include mq($until: xs) {

        &__icons, &__text {
            text-align: justify;
            padding: 0;
        }

        &__icons {
            .icon svg {
                width: 20vw;
            }
        }
    }
}


.article {
    display: flex;
    align-items: center;
    position: relative;

    &__text, &__image {
        width: 50%;
    }

    &__image {
        img {
            height: 720px;
            object-fit: cover;
        }

        svg {
            width: 100%;
        }
    }

    &__text {
        padding: 0 5%;
        max-width: calc(535px + 10%);
    }

    .button {
        margin-left: calc(90% - 160px);
        margin-top: 20px;
        min-width: 160px;
    }

    &__logo {
        padding: 5vw;
        position: absolute;
        top: -3vw;
        left: 3vw;
        background-image: url('../../images/elli2.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 5;

        @include mq($until: xxl) {
            top: 0
        }

        img {
            width: 12vw;
        }
    }

    h3 {
        margin-bottom: 50px;
    }

    @include mq($until: md) {
        flex-direction: column-reverse;
        &__text {
            width: 100%;
            padding: 0;
        }

        &__image {
            display: none;
        }

        .button {
            margin-left: calc(100% - 160px);
        }

        &__logo {
            display: none;
        }

    }

    @include mq($until: xs) {
        &__text {
            p {
                text-align: justify;
            }
        }
    }
}

