.news-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;

    &__header{
        margin-top: 15px;
        margin-top: 50px;
    }

    &__more{
        margin-top: auto;
        padding-top: 15px;
    }

    img {
        width: 100%;
        border-radius: rem(25px);
    }

    @include mq($until: md) {
        &__header {
            margin-top: 25px;
            font-size: rem(22px);
            text-align: center;     
            margin-left: auto;
            margin-right: auto;   
        }
    }
}