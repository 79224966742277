.map {
  height: 800px;
}

.map-container {
  text-align: center;
  margin-top: 50px;

  h2 {
      margin-bottom: -60px;
  }

  .map {

    z-index: 0;
    height: 40vw;
    width: 100%;

    &__center {
        width: 100%;
        position: relative;
    }

    &__substract {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(./../../images/subtract.png);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 2;
        pointer-events: none;
    }


    &__icons {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-self: center;

        li {
            transform-origin: center;
            cursor: pointer;
            position: relative;

            &:hover {
                background-color: $color-main;
                path {
                    fill: white;
                }
                span {
                    color: white;
                }
            }
        }
        
        &_left {
            li {
                &:nth-of-type(1) {
                    transform: rotate(34deg) scale(1.1);
                    left: 5vw;
                    bottom: 3vw;
                }
                &:nth-of-type(2) {
                    transform: rotate(-10deg) scale(.9);
                }
                &:nth-of-type(3) {
                    transform: rotate(-30deg) scale(.8);
                    left: 5vw;
                    top: 3vw;
                }
            }
        }
        
        &_right {
            li {
                &:nth-of-type(1) {
                    transform: rotate(4deg) scale(0.9);
                    right: 5vw;
                    bottom: 3vw;
                }
                &:nth-of-type(2) {
                    transform: rotate(-10deg) scale(.9);
                }
                &:nth-of-type(3) {
                right: 5vw;
                top: 3vw;
                transform: rotate(20deg) scale(1.1);
                }
            }
        }
    }

    &__icon {
        padding-top: 10px;
        box-shadow: 0 8px 20px #00000024;
        border-radius: 18%;
        background-color: white;
        flex-direction: column;
        display: flex;
        width: 170px;
        height: 170px;
        align-items: center;
        justify-content: center;
        transition: all .3s;

        span {
            font-size: 14px;
            font-weight: 600;
        }

        svg {
            margin-bottom: 15px;
            width: 60px;
            height: 60px;
        }
    }

    
    @include mq($until: lg) {
        height: 60vw;
    }

}

.svg-map {
    margin: 0 5vw;
    z-index: 0;
}

&> div {
    display: flex;
    justify-content: center;
    margin: 100px 5vw;
}

.map-place {
    opacity: .5;
    transition: all .3s;

    &.active {
        opacity: 1;
    }
}

@include mq($until: xl) {
    .map {
        &__icon {
            width: 130px;
            height: 130px;

            span {
                font-size: rem(11px);
            }
        }

        &__icons {
            &_left {
                li {
                    &:nth-of-type(1) {
                        transform: rotate(34deg) scale(1.15);
                    }
                    &:nth-of-type(2) {
                        transform: rotate(-10deg) scale(.9);
                    }
                    &:nth-of-type(3) {
                        transform: rotate(-30deg) scale(.8);
                    }
                }
            }
            
            &_right {
                li {
                    &:nth-of-type(1) {
                        transform: rotate(4deg) scale(0.9);
                    }
                    &:nth-of-type(2) {
                        transform: rotate(-10deg) scale(.9);
                    }
                    &:nth-of-type(3) {
                        transform: rotate(20deg) scale(1.1);
                    }
                }
            }
        }

    }
    .svg-map {
        margin: 0 1vw;
    }
}

@include mq($until: lg) {

    h2 {
        margin-bottom: -100px;
    }

    .map {

        &__icons {
            position: absolute;

            &_left {
                left: 20px;
            }
            
            &_right {
                right: 20px;
                
            }
        }
    }

    .svg-map {
        margin: 0 1vw;
        width: 100%;
    }
}

@include mq($until: md) {

h2 {
    margin-bottom: -50px;
}

    .svg-map {
        height: 60vw;
    }

    .map {
        &__icons {
            position: relative;

            &_left {
                left: 0;
                margin-bottom: -80px;
                width: 100%;
                ul {
                    width: 100%;
                }
                li {
                    &:nth-of-type(1) {
                        transform: rotate(4deg) scale(1) translateX(0);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                    &:nth-of-type(2) {
                        transform: rotate(-10deg) scale(1) translateY(0);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                    &:nth-of-type(3) {
                        transform: rotate(20deg) scale(1) translateX(0);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                }
            }
            
            &_right {
                right: 0;
                margin-top: -80px;
                width: 100%;
                ul {
                    width: 100%;
                }
                li {
                    &:nth-of-type(1) {
                        transform: rotate(4deg) scale(1) translateX(0);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                    &:nth-of-type(2) {
                        transform: rotate(-10deg) scale(1) translateY(0);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                    &:nth-of-type(3) {
                        transform: rotate(20deg) scale(1) translateX(0);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                }
            }
        }
    }

    & > div {
        flex-direction: column;

        ul {
            display: flex;
            justify-content: space-evenly;
        }
    }
}

@include mq($until: sm) {
    
h2 {
    margin-bottom: -20px;
}

    &> div {
        padding: 0;
        margin: 40px 0;
    }

    .map {

        height: 80vw;
        &__icons {

            &_left {
                margin-bottom: -40px;
            }
            
            &_right {
                margin-top: -40px;
            }
        }
    }

    .map__icon {
        width: 22vw;
        height: 22vw;

        svg {
            width: 9vw;
            height: 9vw;
            margin-bottom: 4px;
        }

        span {
            font-size: 1.8vw;
        }
    }
}
}