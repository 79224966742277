.house {

    h1 {
        margin: 0;
        font-size: rem(28px);
    }

    &__status {
        span {
            display: flex;
            &::before {
                width: 30px;
                height: 30px;
                content: '';
                display: block;
                margin-right: 10px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.dostepny {
                &::before {
                    background-image: url(./../../images/status/dostepny.png);
                }
            }

            &.rezerwacja {
                &::before {
                    background-image: url(./../../images/status/rezerwacja.png);
                }
            }

            &.sprzedany {
                &::before {
                    background-image: url(./../../images/status/sprzedany.png);
                }
            }
        }
    }

    &__mobile-img {
        height: 23vw;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        align-items: center;
        background-color: white;
        box-shadow: 0px 0px 16px #0000000f;
        padding: 10px 20px;
        margin-bottom: 50px;

        & > div {
            font-size: rem(18px);
            font-weight: 100;

            &:nth-child(1) {
                display: flex;
                align-items: center;

                span {
                    font-size: rem(20px);
                }
            }
        }
    }

    &__floors {
        display: grid;
        column-gap: 40px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 200px;

        h4 {
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 50px;
        }

        &> div {
            position: relative;
        }
    }

    &__roof {
        flex-direction: column;
        display: flex;
        width: 300px;
    }
    
    &__rooms {
        display: grid;
        column-gap: 40px;
        grid-template-columns: 1fr 1fr 1fr;

        h4 {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: rem(19px);
        }

        li {
            margin-top: 6px;
            border-bottom: 1px solid $color-main;
            padding-bottom: 6px;

            color: $color-main;

            &:first-child {
                border-bottom: 2px solid $color-main;
            }

            span {
                font-weight: 600;
            }
        }
    }

    &__way {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        img {
            width: 47%;
            object-fit: contain;
        }
    }

    &__direction {
        background-image: url('/themes/default/assets/images/rose.svg');
        width: 100%;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    li {
        display: flex;
        width: 230px;
        justify-content: space-between;
    }

    @include mq($until: xxl) {

        h1 {
            font-size: 1.55rem;
        }

        &__header {
            & > div {
                font-size: rem(16px);
                font-weight: 100;
            }
        }

        &__floors {
            margin-top: 150px;
        }
    }

    @include mq($until: xl) {

        .separator {
            height: 70px;
        }

        &__floors {
            margin-top: 120px;
        }

        &__status {
            margin-top: 10px;
        }
        
        &__header {
            padding: 15px 20px;

            & > {
                div {
                    &:not(:last-child) {
                        display: flex;
                        flex-direction: column;
                        text-align: end;
                    }

                    &:nth-child(1) {
                        align-items: flex-start;

                        .separator {
                            display: none;
                        }
                    }
                }
            }
        }

        &__buttons {
            flex-direction: row;
        }
    }

    @include mq($until: lg) {
        &__floors {
            margin-top: 80px;

            h4 {
                font-size: rem(18px);
                left: 30px;
            }
        }

    }

    @include mq($until: md) {

        &__header {
            flex-direction: column;
            max-width: 340px;
            & > {
                div {
                    &:not(:last-child) {
                        flex-direction: row;
                        margin: 10px 0;
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }

        &__mobile-img {
            width: 48%;
            display: block; 
            margin-top: 30px;
            height: 35vw;

            img {
                margin-top: -40px;
            }
        }

        &__rooms {
            &> div {
                display: flex;
                justify-content: space-between;

                ul {
                    width: 48%;
                }
            }
        }

        .separator {
            display: none;
        }

        &__status {
            margin-top: 0;
        }

        &__floors {
            display: none;
        }

        li {
            width: auto;
            max-width: 300px;
            margin-left: auto;
        }

        &__rooms {
            column-gap: 20px;
            margin-bottom: 30px;
        }

        &__buttons {
            width: 100%;
            justify-content: space-between;
            display: flex;
            margin-top: 10px;

            a {
                width: 49%;
            }
        }

        &__rooms {
            column-gap: 20px;
            grid-template-columns: 1fr;
        }

        &__roof {
            ul {
                width: 100%!important;
            }
        }
    }

    @include mq($until: sm) {
        li {
            width: 250px;
        }
    }

    @include mq($until: xs) {
        li {
            width: 270px;
        }
        &__mobile-img {
            width: 100%;
            height: auto;
            max-width: 350px;
            margin: 25px auto 0;
        }
            
        &__header {
            margin: 0 auto 25px;
        }

        ul {
            width: 100%;
            margin: -20px auto 20px;
            width: fit-content!important;
        }

        &__roof {
            margin: 0 auto;
            width: auto;
        }

        &__rooms {
            &> div {
                flex-direction: column;

                ul {
                    width: 100%;
                }
            }
        }
    }
}


.w-spacer {
    background-size: cover;
    position: relative;

    * {
        z-index: 5;
        position: relative;
    }

    h4 {
        margin: 40px 0;
    }

    &__box {
        width: 100%;
        height: 690px;
        margin: 0 auto 50px;
    }

    @include mq($until: md) {
        &__box {
            height: 590px;
        }
    }
}
