.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(50px);

  :focus{outline: none;}

  &__row {
    position: relative;
    margin-top: 20px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    position: relative;
  }

  .fakebox {
    width: 30px;
    height: 30px;
    border: 1px solid #C1BBA9;
    display: block;
    transition: all .3s;
    margin-right: 10px;

    & + span {
      width: 89%;
      font-size: rem(15px);
      font-weight: 100;
      font-style: italic;
    }
  }

  &__input {
    margin-top: 5px;
    border: 0;
    padding: 8px;
    border: 1px solid #505052;
    background-color: transparent;
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    &--checkbox{
      margin: 0 5px 0 0 ;
      display: none;

      &:checked + label .fakebox {
        background-color: #4f647c56;
      }
    }

    &--textarea{
      padding-bottom: 40px;
      margin-bottom: -7px;
    }

    & ~ .form__border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-main;
      transition: 0.3s;
    }

    &:focus ~ .form__border {
      width: 100%;
      transition: 0.3s;
    }

    &:focus ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

    @include mq($until: md) {
      margin-bottom: rem(10px);
    }

  }

  &__label  {
    position: absolute;
    left: 12px;
    width: 100%;
    top: 8px;
    color: #505052;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 10px;
    font-size: rem(15px);
  }

  &__rodo {
    font-size: rem(14px);
    width: 100%;
    margin-bottom: rem(30px);
    margin-top: rem(15px);
    align-items: center;
    justify-content: flex-start;

    label {
      display: flex;
      align-items: center;
    }

    label, input{
      cursor: pointer;
    }
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }

  @include mq($until: sm) {
    &__wrapper {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &__row {
      margin-top: 10px;
    }
  }

}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert{
  h4{
    margin-top: 15px;
    font-size: rem(20px);
  }
}
