.widget{

    margin-top: 150px;
    position: relative;

    svg {
        width: 100%;
        height: 53.5vw;
    }

    &> h2 {
        position: absolute;
        top: 2%;
        left: 7%;
    }

    &__bubble {
        width: 300px;
        height: 250px;
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../../images/elli.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        h2 {
            color: white;
            text-align: center;
            font-size: rem(45px);
            line-height: 1.1;
            margin: 0;
        }
    }

    .house-widget {
        opacity: 0;
        transition: all .3s;
        cursor: pointer;

        &.active {
            opacity: .6;
        }
    }

    @include mq($until: xl) {
        &__bubble {
            width: 250px;
            height: 170px;

            h2 {
                font-size: rem(35px);
            }
        }
    }

    @include mq($until: md) {
        &__bubble {
            width: 190px;
            height: 160px;

            h2 {
                font-size: rem(30px);
            }
        }

        & > h2 {
            left: 0%;
        }

        svg {
            width: 100%;
            height: 57vw;
        }
    }

    @include mq($until: sm) {
        &__bubble {
            width: 150px;
            height: 150px;
            top: -30px;

            h2 {
                font-size: rem(30px);
            }

        }
        & > h2 {
            top: -30px;
        }
    }

    @include mq($until: xs) {
        &__bubble {
            width: 28vw;
            height: 25vw;

            h2 {
                font-size: 4vw;
            }

        }

        & > h2 {
            top: -65px;
        }
    }
}