figure.containerZoom{background-position:50% 50%;position:relative;width:100%;overflow:hidden;cursor:zoom-in;margin:0}figure.containerZoom img{transition:opacity .5s;display:block;width:100%}figure.containerZoom.active img{opacity:0}
.containerZoom:not(.active) {
    background-image: none!important;
}

h4 {
    transition: all .3s;
}

.containerZoom {
    &.active {

        border: 15px solid #0000000f;
        background-color: white;
        border-radius: 10%;

        & ~ h4 {
            opacity: 0;
        }
    }
}