.to-top {
    position: fixed;
    bottom: 5%;
    right: 0;
    z-index: 5;
    cursor: pointer;
    transition: all .3s;

    &__arrow {
        background-image: url(./../../images/arup.svg);
        width: 40px;
        height: 40px;
        background-color: white;
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }

    &:hover {
        padding-bottom: 10px;
    }
}