$delay-interval: 0.3;

@for $i from 1 through 10 {
  .delay#{$i}{
    animation-delay: #{$i*$delay-interval}s;
    @include mq($until: xs){
      animation-delay: #{$delay-interval}s;
    }
  }
}

@-webkit-keyframes fadein {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0.5; }
  to { opacity: 1; }
}