.footer {

  display: grid;
  grid-template-columns: 23% 21% 21% 16% 19%;
  grid-template-rows: 1fr; 
  padding-bottom: 50px;
  padding-top: 100px;
  background-image: url('../../images/vidget-bg-top.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  grid-template-areas: 
    "a1 a2 a3 a4 a5"; 
  

  .a1 { grid-area: a1; }
  .a2 { grid-area: a2; }
  .a3 { grid-area: a3; }
  .a4 { grid-area: a4; }
  .a5 { grid-area: a5; }

  &> div:not(.footer__social) {

    &:nth-child(1) {
      img {
        width: 255px;
      }
    }

    &:nth-child(5) {
      align-self: end;
      justify-self: flex-end;
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    width: 290px;
    align-items: flex-end;

    svg {
      fill: #767676;
      transition: fill .4s;
      width: 33px;
      margin-left: 7px;

      &:hover {
        fill: #292929;
      }
    }
  }

  &__about {
    text-align: justify;
    width: 255px;
    margin-top: 40px;
    font-weight: 100;
  }

  &__address {
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
      color: #767676;
      font-weight: 100;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;

    a {
      color: #7C8082;
      font-weight: 700;
    }
  }

  &__links {

    a {
      color: #767676;
      font-weight: 100;
    }
  }

  a {
    &:hover {
      color: $color-main;
    }
  }

  &-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
  }

  @include mq($until: xl) {
    grid-template-columns: 32% 26% 26% 16%;

    grid-template-areas: 
    "a1 a2 a3 a4"
    "a5 a5 a5 a5"; 
  }

  @include mq($until: md) {
    grid-template-columns: 43% 30% 27%;
    gap: 25px 5px; 
    grid-template-areas: 
    "a1 a2 a3"
    "a4 a4 a5"; 

    &__links {
      display: flex;
      margin: 0;

      a {
        margin-right: 12px;
      }
    }
  }

  @include mq($until: md) {
    &__social {
      justify-content: end;
      align-items: flex-start;
      
      span {
        display: none;
      }
    }
  }

  @include mq($until: sm) {

    grid-template-columns: repeat( auto-fill, minmax(180px, 1fr) );
    grid-template-areas: 
    "a1 a1"
    "a2 a3"
    "a4 a5"; 

    &__social {
      width: 250px;
    }
  }

  @include mq($until: 450px) {

    grid-template-columns: 100%;
    grid-template-areas: 
    "a1"
    "a2"
    "a3"
    "a4"
    "a5"; 

    &__social {
      flex-direction: column;

      span {
        display: none;
      }

      svg {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    &> div:not(.footer__social) {

      &:nth-child(1) {
        img {
          width: 200px;
        }
      }
  
      &:nth-child(5) {
        align-self: end;
        justify-self: flex-start;
      }
    }

    &-small {
      flex-wrap: wrap;

      &__realization {
        margin: 15px auto;
      }
    }
  }

  @include mq($until: xs) {
    .header__logo {
      width: 125px;
      margin: 0 auto;
      display: block;
    }

    text-align: center;

    &__social {
      width: 100%;
      align-items: center;

      svg {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    &__links {
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        margin: 0 5px;
      }
    }

    &__about {
      width: 300px;
      margin: 20px auto;
      text-align: center;
    }

    .a5 {
      width: 100%;
    }
  }
}