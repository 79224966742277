.svg-icon{
  transition: fill .3s ease-in-out;
  @include mq($until: xs){
    max-width: 25px;
  }
}
.contact-data{
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2,1fr);

  &__social {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }

    svg {
      fill: #767676;
      transition: fill .4s;
      width: 33px;

      &:hover {
        fill: #292929;
      }
    }
  }

  &__address {
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
      color: #767676;
      font-weight: 100;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;

    a {
      color: #7C8082;
      font-weight: 700;
    }
  }

  &__section{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    p{
      margin-bottom: 0;
    }

    &--name{
      display: block;
      p{
        font-size: rem(20px);
        font-weight: $fw-semi-bold;
      }
    }
    &--social .svg-icon{
      max-width: unset;
      @include mq($until: xs){
          max-width: 35px;
      }
    }
    &--social a:hover .svg-icon{
        fill: $color-main;
    }
  }
  @include mq($until: xl){
    grid-template-columns: 1fr;
  }

  @include mq($until: md){
    grid-template-columns: repeat(2,1fr);
    gap: 15px;
  }

  @include mq($until: 450px){

    &__social {
      flex-direction: column;
      align-items: baseline;
      svg {
        margin-right: 10px;
      }
    }

    grid-template-columns: 1fr;
  }
  
}